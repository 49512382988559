<template>
  <el-form
    class="income-wrap"
    ref="customerFileRef"
    :model="customerFile"
    v-loading="uploadLoading"
  >
    <div class="edit-select">
      <div class="edit-select-item">
        <div class="downloadColumn">
          <span>点击下载模板：</span>
          <a
            :href="staticFile.projectImportXls.url"
            :download="staticFile.projectImportXls.name"
            >{{ staticFile.projectImportXls.name }}</a
          >
        </div>
      </div>
      <div class="edit-select-item">
        <el-input
          v-model="customerFile.fileName"
          style="width: 300px"
        ></el-input>
        <el-upload
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          action="#"
          :multiple="false"
          :show-file-list="false"
          :on-exceed="handleExceed"
          :on-change="uploadFile"
        >
          <el-button type="default" icon="el-icon-upload2">
            上传文件
          </el-button>
        </el-upload>
      </div>
      <div class="edit-select-item"></div>
    </div>
    <div class="edit-select">
      <div class="edit-select-foot">
        <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
        <el-button
          class="el-button-ext"
          @click="handleSaveCustomer"
          :disabled="saving"
          type="primary"
          >导入
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { projectImport } from "@/service/voucher";
import { downloadStaticFile } from "@/service/common";
import StaticFile from "@/assets/js/static-file";

export default {
  name: "ProjectImport",
  data() {
    return {
      saving: false,
      staticFile: StaticFile,
      customerFile: {
        fileName: "",
        file: {},
      },
      uploadLoading: false,
    };
  },
  computed: {},
  methods: {
    /* 上传错误捕获 */
    handleExceed() {
      this.toast("上传文件出错", "error");
    },
    /* 导入保存 */
    async handleSaveCustomer() {
      this.$refs.customerFileRef.validate((valid) => {
        if (!valid) return;
      });
      if (!this.customerFile.file.name) {
        this.toast("请选择要上传文件！", "warning");
        return;
      }
      this.saving = true;

      let formData = new FormData();
      formData.append("file", this.customerFile.file);

      this.uploadLoading = true;
      const res = await projectImport(formData);
      this.uploadLoading = false;
      if (res.success) {
        this.toast("项目信息导入成功", "success", () => this.handleCancel());
      }
      this.$emit("handReloadCustomerList");
      this.saving = false;
    },
    async downloadTemplate(type) {
      switch (type) {
        case "xlsx":
          downloadStaticFile(
            StaticFiles.CustomerImportTemplateUrl,
            type,
            " 项目信息模板"
          );
          break;
        default:
          this.toast("下载模板文件类型无效", "error");
          break;
      }
    },
    uploadFile(file) {
      this.customerFile.fileName = file.name;
      this.customerFile.file = file.raw;
    },
    /* 重置表单 */
    resetForm() {
      this.$refs.customerFileRef.resetFields();
      this.customerFile.fileName = "";
      this.customerFile.file = {};
    },
    /* 通知父组件关闭dialog */
    handleCancel() {
      this.resetForm();
      this.$emit("handleCancelImportDialog");
    },
    //处理取消
    cancelImport() {
      this.saving = false;
      this.resetForm();
      this.$emit("handleCancelImportDialog");
    },
  },
};
</script>

<style scoped lang="scss">
.income-wrap {
  padding: 0 24px;
}

::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 58px;
  padding-top: 2px;
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

  ::v-deep .el-select, ::v-deep .el-input {
    width: 453px;
  }

.edit-select {
  .edit-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-item:first-child {
    margin-bottom: 50px;
  }

  .edit-select-item:last-child {
    margin-top: 60px;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.edit-message {
  margin-top: 25px;
  margin-bottom: 32px;
  text-align: left;
  font-size: 14px;
  color: #f5222d;
}

.downloadColumn {
  font-size: 16px;
  margin: 0 auto;
  a {
    color: #1890ff;
    font-size: 16px;
    font-weight: 400;
    line-height: 2em;
    text-decoration: none;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}

.downloadBtn {
  padding-left: 0px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #1890ff;
  font-weight: 400;
  line-height: 60px;
  outline: none;
}
</style>
