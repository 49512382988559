<template>
  <div class="cont-warp">
    <div>
      <el-button type="text" class="btn" @click="downloadTemplate('xlsx')">
        系统自定义导入模板.xls
      </el-button>
    </div>
    <div>
      <el-button type="text" class="btn" @click="downloadTemplate('txt')">
        航信金税盘导入模板.txt
      </el-button>
    </div>
    <div>
      <el-button type="text" class="btn" @click="downloadTemplate('xml')">
        百旺税控盘导入模板.xml
      </el-button>
    </div>
  </div>
</template>
<script>
import StaticFiles from '@/assets/js/static-file'
import { downloadStaticFile } from '@/service/common'

export default {
  name: 'CustomerTemplateDownload',
  props: ['isRetailer'],
  methods: {
    async downloadTemplate (type) {
      switch (type) {
        case 'xlsx':
          if (localStorage.getItem('INDUSTRY') == 'RETAIL') {
            downloadStaticFile(StaticFiles.MerchandiseImportZdyRetailTemplateUrl, type, ' 系统自定义导入模板')
            return
          }
          downloadStaticFile(StaticFiles.MerchandiseImportZdyHotelTemplateUrl, type, ' 系统自定义导入模板')
          break
        case 'txt':
          downloadStaticFile(StaticFiles.MerchandiseImportJspTemplateUrl, type, '航信金税盘导入模板')
          break
        case 'xml':
          downloadStaticFile(StaticFiles.MerchandiseImportSkpTemplateUrl, type, '百旺税控盘导入模板')
          break
        default:
          this.toast('下载模板文件类型无效', 'error')
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .cont-warp {
    margin-top: -30px;
    text-align: center;
    padding: 0px 30px 30px 30px;
  }

  .btn {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1890FF;
    line-height: 60px;
    outline: none;
  }

  .bottomBtn {
    display: flex;
    justify-content: flex-end;
  }

  span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
