import { get, post, download, del, put } from './index';

/**
 * 获取商品列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const list = (data) => {
  return get('/customer/list', data).then((res) => res);
};

/**
 * 校验商品码唯一性
 */
export const checkUniqueCode = (param) => {
  return get('/customer/checkUniqueCode', param).then((res) => res);
};

/**
 * 新增商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const insertCustomer = (data) => {
  return post('/voucher/insertVouCustomer', data).then((res) => res);
};
/**
 * 同步往来单位
 * @param data
 * @returns {Promise<unknown>}
 */
export const syncCustom = (data) => {
  return post('/vou/customer/sync').then((res) => res);
};

/**
 * 修改商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const updateCustomer = (data) => {
  return put('/customer', data).then((res) => res);
};

/**
 * 删除商品信息
 * @param param
 * @returns {Promise<unknown>}
 */
export const deleteById = (id) => {
  return del(`/vou/customer/${id}`).then((res) => res);
};

/**
 * 推送商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const pushCustomer = (data) => {
  return post('/vou/customer/push', data).then((res) => res);
};

/**
 * 导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const handleExportExcel = (data) => {
  return download('/customer/exportCustomerExcel', data).then((res) => res);
};

/**
 * 批量删除商品信息
 * @param param
 * @returns {Promise<unknown>}
 */
export const deleteCustomerByIds = (ids) => {
  return del(`/customer/batchDelete/${ids}`).then((res) => res);
};

/**
 * 导入商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const uploadCustomer = (data) => {
  return post('/customer/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};

/**
 * 批量修改商品版本号
 * @param data
 * @returns {Promise<unknown>}
 */
export const updateTaxVersion = (data) => {
  return post('/customer/updateTaxVersion', data).then((res) => res);
};

/**
 * 获取商品列表-手工开票-零售
 * @param data
 * @returns {Promise<unknown>}
 */
export const getCustomerList = (data) => {
  return get('/customer/getCustomerList', data).then((res) => res);
};

/**
 * 获取商品信息列表（按税收分类名称分组）-手工-酒店
 * @param data
 * @returns {Promise<unknown>}
 */
export const getCustomerGroup = (data) => {
  return get('/customer/getCustomerGroup', data).then((res) => res);
};

/**
 * 模糊搜索
 * @param param
 * @returns {Promise<T>}
 */
export const fuzzySearchCustomers = (param) => {
  return get('/customer/fuzzy', param).then((res) => res);
};

/**
 * 填充序列号
 * @param param
 * @returns {Promise<T>}
 */
export const getFillSerialNo = (param) => {
  return get(`/customer/fillSerialNo`).then((res) => res);
};

/**
 * 下载商品Excel导入模板
 * @param data
 * @returns {Promise<unknown | never>}
 */
export const downExcelImportModel = (data) => {
  return download('/customer/downExcelImportModel', data).then((res) => res);
};
/**
 * excel导入客户
 * @param data
 * @returns {Promise<unknown | never>}
 */
export const excelImportCustomer = (data) => {
  return post('/customer/excelImportCustomer', data).then((res) => res);
};
/**
 * 下载模板
 */
export const downCustomerTemplate = (data) => {
  return download('/customer/downCustomerTemplate', data).then((res) => res);
};

/**
 * 【原始订单管理】修改发票类型
 */
export const updateBillingType = (data) => {
  return put('/original/order/billingType', data).then((res) => res);
};
